const exampleQueries = {
  
  coinPrice: [
    {
      id: 1,
      query: '/omni?q=price of 1 bitcoin',
      text: 'price of 1 bitcoin'
    },

    {
      id: 2,
      query: '/omni?q=price of 1 bitcoin',
      text: 'price of 1 bitcoin'
    },

    {
      id: 3,
      query: '/omni?q=price of 2.52 btc',
      text: 'price of 2.52 btc'
    },

    {
      id: 4,
      query: '/omni?q=price of 1 btc in eth',
      text: 'price of 1 btc in eth'
    },

    {
      id: 5,
      query: '/omni?q=price of 20 btc in bitcoin cash',
      text: 'price of 20 btc in bitcoin cash'
    },

    {
      id: 6,
      query: '/omni?q=price of 1 btc in eur',
      text: 'price of 1 btc in eur'
    },

  ],

  volume: [
    {
      id: 1,
      query: '/omni?q=volume of btc',
      text: 'volume of btc'
    },

    {
      id: 2,
      query: '/omni?q=volume of bitcoin cash',
      text: 'volume of bitcoin cash'
    },

    {
      id: 3,
      query: '/omni?q=volume eth',
      text: 'volume eth'
    },

    {
      id: 4,
      query: '/omni?q=vol of bitcoin',
      text: 'vol of bitcoin'
    },

    {
      id: 5,
      query: '/omni?q=vol monero',
      text: 'vol monero'
    },
  ],
  
 totalSupply: [
  {
    id: 1,
    query: '/omni?q=total supply of btc',
    text: 'total supply of btc'
  },

  {
    id: 2,
    query: '/omni?q=total supply btc',
    text: 'total supply btc'
  },

  {
    id: 3,
    query: '/omni?q=total supply of bitcoin cash',
    text: 'total supply of bitcoin cash'
  },

  {
    id: 4,
    query: '/omni?q=total supply ethereum classic',
    text: 'total supply ethereum classic'
  }
 ],

 coinPriceOnDate: [
  {
    id: 1,
    query: '/omni?q=price of 1 btc on 12/12/2018',
    text: 'price of 1 btc on 12/12/2018'
  },

  {
    id: 2,
    query: '/omni?q=price of 1 bitcoin on 12/05/2017',
    text: 'price of 1 bitcoin on 12/05/2017'
  },

  {
    id: 3,
    query: '/omni?q=price of 2.52 btc on 21/01/2019',
    text: 'price of 2.52 btc on 21/01/2019'
  },

  {
    id: 4,
    query: '/omni?q=price of 2.57 btc on 09/04/2017 in eth',
    text: 'price of 1 btc on 09/04/2017 in eth'
  },

  {
    id: 5,
    query: '/omni?q=price of 2.57 eth on 15/10/2018 in bitcoin cash',
    text: 'price of 2.57 eth on 15/10/2018 in bitcoin cash'
  },

  {
    id: 6,
    query: '/omni?q=price of 2.57 ethereum on 15/10/2018 in bch',
    text: 'price of 2.57 ethereum on 15/10/2018 in bch'
  },

  {
    id: 7,
    query: '/omni?q=price of 2.57 ethereum on 15/10/2018 in bitcoin cash',
    text: 'price of 2.57 ethereum on 15/10/2018 in bitcoin cash'
  },

  {
    id: 8,
    query: '/omni?q=price 1 eth on 15/10/2018',
    text: 'price 1 eth on 15/10/2018'
  }
 ],

 coinArithmetic: [
  {
    id: 1,
    query: `/omni?q=${encodeURIComponent('1 btc + 1 eth + 1 etc')}`,
    text: '1 btc + 1 eth + 1 etc'
  },

  {
    id: 2,
    query: `/omni?q=${encodeURIComponent('1 btc + 1 eth + 1 bch + 1 usd + 1 eur + 1 etc')}`,
    text: '1 btc + 1 eth + 1 bch + 1 usd + 1 eur + 1 etc'
  },

  {
    id: 3,
    query: `/omni?q=${encodeURIComponent('1.87 btc + 950 usd + 5 dash')}`,
    text: '1.87 btc + 950 usd + 5 dash'
  },

  {
    id: 4,
    query: `/omni?q=${encodeURIComponent('5 dash + 9.5 eth + 1.87 btc')}`,
    text: '5 dash + 9.5 eth + 1.87 btc'
  },

  {
    id: 5,
    query: `/omni?q=${encodeURIComponent('1.87 btc + 9.5 eth + 5 dash in usd')}`,
    text: '1.87 btc + 9.5 eth + 5 dash in usd'
  },

  {
    id: 6,
    query: `/omni?q=${encodeURIComponent('1.87 btc + 9.5 eth + 5 dash in bch')}`,
    text: '1.87 btc + 9.5 eth + 5 dash in bch'
  },

  {
    id: 7,
    query: `/omni?q=${encodeURIComponent('1 bitcoin + 1 ethereum + 1 ethereum classic')}`,
    text: '1 bitcoin + 1 ethereum + 1 ethereum classic'
  },

  {
    id: 8,
    query: `/omni?q=${encodeURIComponent('1 btc + 1 ethereum + 1 bitcoin cash + 1 dash + 1 monero + 1 etc')}`,
    text: '1 btc + 1 ethereum + 1 bitcoin cash + 1 dash + 1 monero + 1 etc'
  },

  {
    id: 9,
    query: `/omni?q=${encodeURIComponent('1.87 btc - 9.5 eth + 5 dash')}`,
    text: '1.87 btc - 9.5 eth + 5 dash'
  } 
 ],

coinPriceShortForm: [
  {
    id: 1,
    query: '/omni?q=1 btc in usd',
    text: '1 btc in usd'
  },

  {
    id: 2,
    query: '/omni?q=1 bitcoin in usd',
    text: '1 bitcoin in usd'
  },

  {
    id: 3,
    query: '/omni?q=1 btc in eth',
    text: '1 btc in eth'
  },

  {
    id: 4,
    query: '/omni?q=bitcoin cash in usd',
    text: 'bitcoin cash in usd'
  },

  {
    id: 5,
    query: '/omni?q=ethereum in ethereum classic',
    text: 'ethereum in ethereum classic'
  },
]
}

export default exampleQueries
