import React, { useLayoutEffect } from "react"
import { graphql } from "gatsby"
import axios from "axios"
import Layout from "../components/layout"
import queryString from "query-string"
import exampleQueries from "../data/exampleQueries"
import SEO from "../components/seo"

import CollapsableList from "../components/CollapsableList"
import _ from "lodash"

import '../css/omni.scss'

// const OMNI_BACKEND_URL = 'http://0.0.0.0:5000'
const OMNI_BACKEND_URL = 'https://search.invizi.co'

class Omni extends React.Component {

  state = {
    q: '',
    result: ''
  }

  onRequestAccessClick = () => {
    this.refs.requestAccess.scrollIntoView({behavior: 'smooth'})
  }

  componentDidMount () {
    const { location } = this.props
    const searchParam = queryString.parse(location.search)
    
    if (searchParam.q) {
      this.setState({
        q: searchParam.q
      })
      this.query(searchParam.q)
    }
  }

  query = _.debounce((value) => {
    axios.get(`${OMNI_BACKEND_URL}`, {params: {q: value}}).then((response) => {
      if (response.data && response.data.data && response.data.data[0]) {
        this.setState({result: response.data.data[0].raw})
      } else {
        this.setState({result: []})
      }
    })
  }, 500)

  _handleChange =  (event) => {
    const {name, value} = event.target

    this.setState({
      [name]: value
    })
    this.query(value)
  }

  handleSubmit = (event) => {
    this.query(this.state.q)
    event.preventDefault()
  }

  setInput = (value) => {
    this.setState({
      q: value
    })
    this.query(value)
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    const keys = Object.keys(exampleQueries)
    const collapsableComponents = keys.map(key => <CollapsableList key={key} examples={exampleQueries[key]} setInput={this.setInput} />)
    
    return (
      <Layout location={this.props.location} title={siteTitle} specialClass="standard-page">
        <SEO
          title='Cryptocurrency Calculator'
          description='Check any price, volume or do arithmetics with cryptocurrencies'
        />
        <section className="hidden"></section>
        <section className="">
          <div className="section-intro">
            <h1 className="lead">Cryptocurrency Calculator</h1>
          </div>
          <div className="container col column is-half center">
            <form onSubmit={this.handleSubmit}>
              <input
                className="input is-large omni-input"
                type="text"
                onChange={this._handleChange}
                placeholder="1 btc + 1 eth"
                name="q"
                value={this.state.q}
              />
            </form>
            {this.state.q && this.state.result &&
              <div className="omni-result">
                {this.state.result.length > 0 ? this.state.result : 'No Result'}
              </div>
            }
            <div className="omni-examples">
              <div>Examples:</div>
              <ul>
                {collapsableComponents}              
              </ul>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Omni

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
