import React from "react"

function List(props) {
  return (
    <div>
      <span className="link-query" onClick={() => props.setInput(props.text)}>{props.text}</span>
    </div>  
  )
}

export default List
