import React, { Component } from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import List from "./List"

class CollapsableList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showMoreExamples: false
    }
  }

  handleMoreExamples = () => {
    this.setState(prevState => {
      return {
        showMoreExamples: !prevState.showMoreExamples
      }
    })
  }
  
  render() {
    const [mainExample, ...hiddenExamples] = this.props.examples

    const iconValue = this.state.showMoreExamples ? 'minus' : 'plus'
    const show = this.state.showMoreExamples ? '' : 'hidden'

    return (
      <li>
        <span className="link-query" onClick={() => this.props.setInput(mainExample.text)} style={{marginRight: '10px'}}>{mainExample.text}</span>
        <FontAwesomeIcon icon={iconValue} onClick={this.handleMoreExamples}/>
        <ul className={show}>
          {hiddenExamples.map(item => <List key={item.id} query={item.query} text={item.text} setInput={this.props.setInput} />)}
        </ul>
      </li>
    )
  }
}

export default CollapsableList
